/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Dosis_200ExtraLight = require('./Dosis_200ExtraLight.ttf');
export const Dosis_300Light = require('./Dosis_300Light.ttf');
export const Dosis_400Regular = require('./Dosis_400Regular.ttf');
export const Dosis_500Medium = require('./Dosis_500Medium.ttf');
export const Dosis_600SemiBold = require('./Dosis_600SemiBold.ttf');
export const Dosis_700Bold = require('./Dosis_700Bold.ttf');
export const Dosis_800ExtraBold = require('./Dosis_800ExtraBold.ttf');
